
import React from 'react';
import Search from '../components/Search';
// import Features from '../components/Features';

function Home() {
  return (
    <div> 
    <Search />
    {/* <Features /> */}
    </div>
  )
}

export default Home